<template>
    <popup id="edit-wishlist" class="edit-wishlist-popup">
        <div class="d-flex mb-1">
            <h5 class="title-16 mb-0 weight-700">
                {{$t('wishlist.edit_name')}}
            </h5>
            <span class="weight-100 ms-auto me-2" :class="[change.new.name.length === 50 ? 'color-main-red':'color-dark-gray-200']">{{change.new.name.length}} / 50</span>
        </div>
        <main-input v-model="change.new.name" id="wishlist-name" :max="50" disableIcon />
        <div class="d-flex mt-3 align-items-center">
            <h5 class="title-15 mb-0 weight-700">
                {{$t('wishlist.description')}}
            </h5>
            <h3 class="size-13 color-gray-1001 mb-0 weight-400 ms-2">
                {{$t('wishlist.add_desc_optional')}}
            </h3>
            <span class="d-flex weight-100 ms-auto me-2" :class="[change.new.note.length === 200 ? 'color-main-red':'color-dark-gray-200']">{{change.new.note.length}} / 200</span>
            <span @click="() => {change.new.note = ''}" class="d-flex color-blue-600 hover-color-main-red cursor-pointer">{{$t('cart.clear')}}</span>
        </div>
        <textarea v-model="change.new.note" class="input-description form-control mt-2 size-14"
        :placeholder="$t('cart.press_enter_to_save')" maxlength="200" rows="4" />
    </popup>
</template>

<script>
import Popup from '../../../common/components/popup/Popup'
import MainInput from '../../../common/components/inputs/MainInput'

export default {
    name: 'EditWishListPopUp',
    components: { Popup, MainInput},
    props: {
        listSelected: {
            type: Object
        }
    },
    data() {
        return {
            change: {
                old: {
                    name: '',
                    note: ''
                },  
                new: {
                    name: '',
                    note: ''
                }
            },
            listenerTimeout: null,
        }
    },
    methods: {
        async sendInfo(){
            const validate = (field) => {
                const value = field.replace(/ +(?= )/g,''); //removing multiple spaces
                return value && value !== ' ' && !value.match(/\s{2,}/g); //validating equality on the old object.
            }
            const valid_name = validate(this.change.new.name);
            const valid_note = validate(this.change.new.note);
            const is_different = JSON.stringify(this.change.new) !== JSON.stringify(this.change.old);
            //validate by name and note only if there was a note in first place, otherwise validate by name.
            if ((this.change.new.note !== '' ? valid_note && valid_name : valid_name) && is_different) {
                this.$emit('changing', true);
                await this.$store.dispatch("wishlist/addProductToList", { list_id: this.listSelected._id, name: this.change.new.name, note: this.change.new.note, action: 'edit-wishlist' });
            }
        },
        handleOutsideNoteClick(evt) {
            const el = document.getElementById('edit-wishlist');
            if (!el || !(el == evt.target || el.contains(evt.target))) this.$emit('hideEdit');
        },
        enterHandler(e) {
            if (e.key === 'Enter') this.$emit('hideEdit');
        }
    },
    created() {
        this.change.new.name = this.listSelected.name; // name
        this.change.old.name = this.listSelected.name;
        this.change.new.note = this.listSelected.note || ''; // note
        this.change.old.note = this.listSelected.note || '';

        this.listenerTimeout = setTimeout(() => {
            document.body.addEventListener('click', this.handleOutsideNoteClick);
            document.body.addEventListener('keypress', this.enterHandler);
        }, 100);
    },
    async beforeDestroy() {
        await this.sendInfo();
        document.body.removeEventListener('click', this.handleOutsideNoteClick);
        document.body.removeEventListener('keypress', this.enterHandler);
        clearTimeout(this.listenerTimeout);
    },
};
</script>

<style scoped>
.edit-wishlist-popup {
    width: 330px;
    padding: 20px;
    z-index: 10 !important
}
.input-description {
    resize: none;
}
</style>