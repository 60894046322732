<template>
    <main-confirm class="delete-wishlist" @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" 
        :deletingItems="formatNames(listNames)" :deletingAll="deleteLabels"
        :confirm-title="deleteLabels.title" :description="deleteLabels.description">
        <icon-delete :size="100"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconDelete from '../../../cart/assets/svg/iconDelete.vue'
export default {
    components: { MainConfirm, IconDelete },
    name: "DeleteWishList",
    props: {
        deleteLabels: {
            type: Object,
            default: () => {
                return {
                    delete_all: false,
                    title: 'wishlist.delete_list_modal.title_singular',
                    description: 'wishlist.delete_list_modal.description_singular',
                }
            }
        },
        listNames: {
            type: Array,
        },
    },
    methods: {
        formatNames(lists) {
            let lists_names = '';
            lists.map((list, index) => { //parsing al list names into the same string adding a comma until the last one.
                index === (lists.length - 1) ? lists_names += list : lists_names += list+', ';
            });
            return lists_names;
        },
    },
}
</script>
<style scoped>
.delete-wishlist::v-deep .list-names{
    display: inline-block;
    vertical-align: bottom;
    max-width: 260px;
}
</style>