import { render, staticRenderFns } from "./iconEmptyWishList.vue?vue&type=template&id=108897a6&scoped=true"
import script from "./iconEmptyWishList.vue?vue&type=script&lang=js"
export * from "./iconEmptyWishList.vue?vue&type=script&lang=js"
import style0 from "./iconEmptyWishList.vue?vue&type=style&index=0&id=108897a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108897a6",
  null
  
)

export default component.exports