<template>
    <div class="row mx-0">
        <template v-if="mobile && !lists.length > 0">
            <router-link to="/account" tag="div" class="wishlist-title d-flex position-relative align-items-center cursor-pointer">
                <div class="icons">
                    <icon-arrow :size="25" color="var(--blue-600)" class="icon-arrow" />
                    <icon-favourite color="var(--main-red)" class="mt-1" :size="24" id="icon-favourite" />
                </div>
                <div class="title-description">
                    <h5 class="my-wish-list title-22 mb-0 cursor-pointer">{{$t("wishlist.my_wish_lists")}}</h5>
                    <h6 class="subtitle-16 mb-0">{{$t(`wishlist.description_my_wish_lists.${viewPort('md') ? 'desktop':'mobile'}`)}}.</h6>
                </div>
            </router-link>
        </template>
        <template v-if="lists.length > 0 && !loadingLists">        
            <div class="d-flex col-md-12 justify-content-between px-0">
                <router-link to="/account" tag="div" class="wishlist-title d-flex position-relative align-items-center cursor-pointer">
                    <div class="icons">
                        <icon-arrow :size="25" color="var(--blue-600)" class="icon-arrow" />
                        <icon-favourite color="var(--main-red)" class="mt-1" :size="24" id="icon-favourite" />
                    </div>
                    <div class="title-description">
                        <h5 class="my-wish-list title-22 mb-0 cursor-pointer">{{$t("wishlist.my_wish_lists")}}</h5>
                        <h6 class="subtitle-16 mb-0">{{$t(`wishlist.description_my_wish_lists.${viewPort('md') ? 'desktop':'mobile'}`)}}.</h6>
                    </div>
                </router-link>
                <a v-if="lists.length > 0" @click.prevent="showDeleteWishlist" class="clear-all d-flex position-relative hover-underline align-items-center align-items-md-start" style="min-width: 25px;">
                    <icon-trash :size="16" color="var(--blue-600)" class="ms-1" />
                    <span class="size-15 d-none d-md-flex">{{$t( selectedLists.length === lists.length || selectedLists.length === 0 ? 'wishlist.delete_all_lists':'cart.delete_selection')}}</span>
                </a>
            </div>
            <wish-list-table ref="wishListTable" @selectedLists="(data) => {selectedLists = data}" class="mt-4" />
        </template>
        <template v-else-if="lists.length === 0 && !loadingLists">
            <div class="d-flex aling-items-center justify-content-center">
                <icon-empty-wish-list :size="70" style="margin-bottom: -5%;" @mousemove.native="moveElements" :init="init"/>
            </div>
            <without-results @click="$router.replace({path: '/shop'})" class="mt-1" description="wishlist.description_wish_list_empty" title="wishlist.your_wish_list_is_empty" titleButton="wishlist.add_now">
                <template #button-icon>
                    <icon-search :size="15" color="#fff" class="me-2"/>
                </template>
            </without-results>
        </template>
        <div v-else-if="loadingLists" class="col-md-12" /> 
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import WishListTable from '../components/WishListTable.vue'
import WithoutResults from '../../../common/components/modals/WithoutResults.vue'

import IconTrash from '../../../common/svg/iconTrash.vue'
import IconFavourite from '../../../common/svg/iconFavourite.vue'
import IconSearch from '../../../common/svg/iconSearch.vue'
import IconEmptyWishList from '../assets/svg/iconEmptyWishList.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'

export default {
    name: "WishList",
    components: {
        WishListTable,
        WithoutResults,
        IconTrash,
        IconSearch,
        IconEmptyWishList,
        IconArrow,
        IconFavourite,
    },
    computed: {
        ...mapGetters({
            lists: "wishlist/lists",
            loadingLists: "wishlist/loadingLists",
            mobile: "common/mobile"
        }),
    },
    data() {
        return {
            init: false,
            deleteLabels: { title: 'wishlist.delete_list_modal.title_singular', description: 'wishlist.delete_list_modal.description_singular' },
            selectedLists: [],
        }
    },
    methods: {
        goBack(){
            this.$router.go(-1)
        },
        moveElements(e) {
            document.querySelectorAll('.object').forEach((move) => {
                let moveValue = move.getAttribute("data-value");
                let valueX = 0;
                if (move.getAttribute("data-value-x")) valueX = move.getAttribute("data-value-x");
                let x = (( e.clientX * moveValue ) / 100) + parseInt(valueX);
                let y = (( e.clientY * moveValue ) / 100);
                move.style.transform = `translateX(${x}px) translateY(${y}px)`
            });
        },
        showDeleteWishlist() {
            this.$refs.wishListTable.showDelete();
        }
    },
    mounted() {
        this.init = true;
    }
}
</script>

<style scoped>
    .clear-all > span {
        color: var(--blue-600);
        transition: all .15s ease-in-out !important;
    }
    .clear-all:hover > span {
        margin-right: 21px;
        color: var(--main-red) !important;
    }
    .clear-all > svg {
        position: absolute;
        right: 0px;
        transition: all .15s ease-in-out, fill .05s ease-in-out;
        width: 25px !important;
        opacity: 1;
    }
    .clear-all:hover > svg {
        color: var(--main-red) !important;
        opacity: 1;
    }
    .object { transition: var(--transition-1); }
    .wishlist-title > .icons > .icon-arrow { display: none; }
    .wishlist-title:hover > .icons > .icon-arrow { display: block; }

    .wishlist-title:hover > .icons > #icon-favourite { /* hiding the heart */
        animation: icon-heart 0.3s;
        animation-fill-mode: forwards;
    }
    .wishlist-title > .icons > #icon-favourite { /* showing the heart */
        animation: show-icon-heart 0.3s; 
        animation-fill-mode: forwards;
    }
    .wishlist-title:hover > div > .my-wish-list { color: var(--blue-600); }
    .icon-arrow {
        animation: container-icon-arrow 0.3s;
        animation-fill-mode: forwards;
    }
    .icon-favourite {
        position: absolute;
        top: 0;
    }
    .title-description {
        background: #fff;
        z-index: 2;
        padding: 0px 18px;
        min-width: 290px;
        max-width: 90%;
    }
    .wishlist-title > .icons{
        width: 24px;
    }
    .button-secondary::v-deep > span > div {
        margin-right: 0.5rem;
    }
    @keyframes container-icon-arrow {
        from{
            opacity: 0;
            transform: translateX(10px) rotate(180deg)
        }
        to{
            opacity: 1;
            transform: translateX(0px) rotate(180deg)
        }
    }
    @keyframes show-icon-heart {
        from{
            opacity: 0;
            transform: translateX(10px)
        }
        to {
            opacity: 1;
            transform: translateX(0px)
        }
    }
    @keyframes icon-heart {
        from {
            opacity: 1;
            transform: translateX(0px)
        }
        to {
            opacity: 0;
            transform: translateX(50px)
        }
    }

    @media (min-width: 576px) {
        .title-description {
            max-width: 200px;
        }
    }
    @media (min-width: 768px) {
        .clear-all > svg{
            top: 2px;
            width: 18px !important;
            opacity: 0;
        }
        .title-description {
            max-width: none;
        }
    }
</style>
        