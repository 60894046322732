<template>
    <icon-base :width="size + '%'" :fill="color" viewBox="0 0 876.35 522.385" class="icon-empty-wish-list">
        <g id="Wishlist" transform="translate(-988.26 2729.54)">
        <g id="Wishlist-2" data-name="Wishlist">
        <rect id="Rectangle_1665" data-name="Rectangle 1665" width="876.35" height="522.385" transform="translate(988.26 -2729.54)" fill="none"/>
        <g id="Wishlist_floating_items" data-name="Wishlist floating items">
            <g class="object" :class="[init ? 'init group-6733' : '']" data-value="5">
                <path id="Square" d="M1219.133-2442.289l-13.56-1.866a1.473,1.473,0,0,1-1.257-1.657l1.863-13.567a1.478,1.478,0,0,1,1.665-1.266h0l13.56,1.866a1.482,1.482,0,0,1,1.255,1.669l-1.873,13.564a1.464,1.464,0,0,1-1.643,1.259h0Zm-12.829-3.385,12.518,1.725a.4.4,0,0,0,.442-.335l1.726-12.528a.391.391,0,0,0-.334-.44h0l-12.513-1.719a.389.389,0,0,0-.441.331v0h0l-1.732,12.529a.391.391,0,0,0,.335.437Z" fill="#eceff0"/>
            </g>
            <g class="object" :class="[init ? 'init group-6734' : '']" data-value="2">
                <path id="triangle" d="M1281.478-2515.191a1.837,1.837,0,0,1-1.673-.77l-9.844-13.819a1.833,1.833,0,0,1-.178-1.858,1.87,1.87,0,0,1,1.517-1.077l17.1-1.517a1.868,1.868,0,0,1,2.008,1.719h0a1.867,1.867,0,0,1-.165.925l-7.251,15.335A1.851,1.851,0,0,1,1281.478-2515.191Zm-9.633-15.873,9.406,13.2,6.926-14.643Z" fill="#eceff0"/>
            </g>
            <g id="box" class="object" :class="[init ? 'init group-6743' : '']" data-value="10" data-value-x="-100">
                <path id="Path_2849" data-name="Path 2849" d="M1232.441-2604.441l-4.361-15.408a7.569,7.569,0,0,0-4.977-4.843l-22.1-5.6a7.584,7.584,0,0,0-6.682,1.891l-15.888,16.352a7.574,7.574,0,0,0-1.7,6.733l4.363,15.408a7.577,7.577,0,0,0,4.978,4.842l22.1,5.6a7.572,7.572,0,0,0,6.674-1.891l15.893-16.352A7.578,7.578,0,0,0,1232.441-2604.441Zm-37.093-22.965a5.69,5.69,0,0,1,2.476-1.374h0a5.7,5.7,0,0,1,2.828-.128l9.237,2.339a2.6,2.6,0,0,0-.22.209l-8.611,9-12.046-3.147a2.652,2.652,0,0,0-.3-.066Zm-12.881,37.108-4.36-15.407a6.205,6.205,0,0,1,1.352-5.349l7.644-7.867c.009-.009.022-.013.031-.023a1.563,1.563,0,0,1,1.509-.181l11.283,2.947-7.358,7.687a7.676,7.676,0,0,0-1.668,6.755l4.361,15.4a7.574,7.574,0,0,0,1.4,2.479l-10.242-2.6A6.211,6.211,0,0,1,1182.467-2590.3Zm31.359,7.946a6.451,6.451,0,0,1-5.305,1.5l-7.9-2c-.012,0-.02-.013-.032-.016a6.19,6.19,0,0,1-3.952-3.845l-4.361-15.409a6.3,6.3,0,0,1,1.327-5.378l7.907-8.262,11.058,2.889a6.306,6.306,0,0,1,3.951,3.886l.064.226-1.88,1.935a.715.715,0,0,0-.2.416l-.588,5.073a.7.7,0,0,0,.022.276l3.207,11.346a.716.716,0,0,0,.513.5.732.732,0,0,0,.689-.195l2.675-2.752a6.2,6.2,0,0,1-1.494,3.925c-.012.011-.013.029-.023.041Zm4.007-25.651,1.8-1.848,1.847,1.338,3.031,10.709-6.329,6.507-2.835-10.015.545-4.693,1.943-2Zm11.887,9.3-7.355,7.566a7.522,7.522,0,0,0-.03-2.377l3.49-3.589a.714.714,0,0,0,.174-.694l-3.207-11.344a.709.709,0,0,0-.269-.383l-2.546-1.848a.716.716,0,0,0-.933.079l-1.356,1.4a7.657,7.657,0,0,0-4.752-4.357l-10.294-2.69,8.061-8.423a1.564,1.564,0,0,1,1.378-.634h.031l10.645,2.7a6.2,6.2,0,0,1,3.953,3.847l4.36,15.407a6.207,6.207,0,0,1-1.35,5.339Z" fill="#eceff0"/>
                <path id="Path_2850" data-name="Path 2850" d="M1216.2-2602.588a1.727,1.727,0,0,0,2.146,1.163,2.785,2.785,0,0,0,1.215-.774,4.963,4.963,0,0,0,1.266-4.358,1.806,1.806,0,0,0-.046-.193,1.726,1.726,0,0,0-2.146-1.159,2.752,2.752,0,0,0-1.214.771,4.97,4.97,0,0,0-1.266,4.36A1.58,1.58,0,0,0,1216.2-2602.588Zm2.247-3.556a1.428,1.428,0,0,1,.578-.393h0c.113-.033.3-.086.428.362a3.583,3.583,0,0,1-.915,2.974,1.431,1.431,0,0,1-.58.393c-.112.036-.3.086-.425-.364A3.581,3.581,0,0,1,1218.445-2606.144Z" fill="#eceff0"/>
            </g>
            <g class="container-object">
                <g class="object" :class="[init ? 'init ellipse-418' : '']" data-value="2">
                    <circle id="Ellipse_418" data-name="Ellipse 418" cx="8.745" cy="8.745" r="8.745" transform="translate(1303.165 -2647.196)" fill="none" stroke="#eceff0" stroke-width="2"/>
                </g>
            </g>
            <g class="container-object">
                <g class="object" data-value="2" :class="[init ? 'init ellipse-419' : '']">
                    <circle id="Ellipse_419" data-name="Ellipse 419" cx="6.959" cy="6.959" r="6.959" transform="translate(1627.559 -2531.669)" fill="none" stroke="#eceff0" stroke-width="2"/>
                </g>
            </g>
            <g class="object" :class="[init ? 'init path-2729' : '']" data-value="3">
                <path id="star" d="M1572.227-2622.555a8.219,8.219,0,0,1-8.219,8.219,8.218,8.218,0,0,1,8.219,8.217h0a8.218,8.218,0,0,1,8.218-8.218h0a8.217,8.217,0,0,1-8.218-8.216h0Z" fill="#eceff0"/>
            </g>
                <g id="Group_6745" data-name="Group 6745" class="object" :class="[init ? 'init group-6745' : '']" data-value="6">
                    <path id="Sqaure" d="M1565.889-2487.28l-9.768-1.346a1.062,1.062,0,0,1-.9-1.194l1.342-9.772a1.065,1.065,0,0,1,1.2-.913h0l9.769,1.345a1.067,1.067,0,0,1,.9,1.2l-1.348,9.772a1.057,1.057,0,0,1-1.187.906h0Zm-9.156-2.753,8.937,1.23,1.233-8.945-8.934-1.226Z" fill="#eceff0"/>
                </g>
            <g id="box_2" data-name="box 2" class="object" :class="[init ? 'init group-6756' : '']" data-value="6">
            <path id="Path_2851" data-name="Path 2851" d="M1686.639-2611.164l-20.626-16.479a.653.653,0,0,0-.647-.1l-24.039,9.422-.215-.178-.325.391a.657.657,0,0,0-.4.511l-3.06,20.194a.657.657,0,0,0,.241.609l20.623,16.478c.009.007.022.006.031.013a.641.641,0,0,0,.269.118l.01.005h0a.64.64,0,0,0,.3-.033h.034l24.578-9.637a.657.657,0,0,0,.41-.511l3.056-20.194A.655.655,0,0,0,1686.639-2611.164Zm-27.224,29.317.374-2.473a.658.658,0,0,0-.55-.75.657.657,0,0,0-.75.55h0l-.375,2.479-9.129-7.294,2.865-18.934,9.094,7.554-.523,3.449h0a.658.658,0,0,0,.55.75.657.657,0,0,0,.75-.55l.532-3.52,10.886-4.315-2.843,18.788Zm2.957-31.176a2.994,2.994,0,0,0,2.145.324l8.352,6.829-11.21,4.444-9.149-7.6Zm11.894,6.6-8.579-7.013a1.158,1.158,0,0,0,.175-1l9.673-3.921,9.562,7.64Zm-8.773-19.957,8.867,7.084-9.347,3.789a3.011,3.011,0,0,0-2.286-.357l-7.805-6.381Zm-11.966,4.687,7.534,6.161c-2.447-.671-5.443-1.859-6.344-3.6h0a.656.656,0,0,0-.89-.259.656.656,0,0,0-.274.859,5.637,5.637,0,0,0,1.781,1.929,12.749,12.749,0,0,1-4.109-1.719.655.655,0,0,0-.924.074.655.655,0,0,0,.054.905c.1.086,2.3,1.974,10.9,3.354l-9.883,4.006-8.885-7.38Zm-11.938,4.89,9.091,7.552-2.871,18.966-9.131-7.3Zm41,25.868-10.885,4.271,2.844-18.8,10.89-4.317Z" fill="#eceff0"/>
            <path id="Path_2852" data-name="Path 2852" d="M1660.479-2593.248h0a.655.655,0,0,0-.746.548l-.543,3.6h0a.658.658,0,0,0,.55.75.657.657,0,0,0,.75-.55l.543-3.6a.656.656,0,0,0-.544-.752Z" fill="#eceff0"/>
            </g>
            <g id="box-2" class="object" :class="[init ? 'init group-6762' : '']" data-value="4">
                <path id="Path_2853" data-name="Path 2853" d="M1702.071-2474.575l12.428,4.452a3.521,3.521,0,0,0,3.208-.587l10.068-8.545a.492.492,0,0,0,.036-.7.492.492,0,0,0-.673-.056l-10.069,8.543a2.563,2.563,0,0,1-2.24.409l-12.428-4.454a.493.493,0,0,0-.63.3h0A.5.5,0,0,0,1702.071-2474.575Z" fill="#eceff0"/>
                <path id="Path_2854" data-name="Path 2854" d="M1732.467-2474.205l.7-.594a.493.493,0,0,0,.167-.465l-.624-3.428a5.068,5.068,0,0,0-3.006-3.545l-14.322-5.127a5.079,5.079,0,0,0-4.572.832l-11.594,9.843a5.068,5.068,0,0,0-1.567,4.377l.624,3.427a.486.486,0,0,0,.318.377l.863.31,2.444,13.432a.493.493,0,0,0,.318.376l17.7,6.342h.026a.475.475,0,0,0,.212.016h.022a.491.491,0,0,0,.2-.089c.008,0,.019,0,.027-.011l14.34-12.171a.5.5,0,0,0,.166-.464Zm-33.846,1.717a4.108,4.108,0,0,1,1.233-3.448l11.594-9.843a4.29,4.29,0,0,1,3.6-.655l14.321,5.129a4.114,4.114,0,0,1,2.368,2.793l.573,3.148-13.209,11.211a4.3,4.3,0,0,1-3.524.667c-.025-.007-.051-.007-.076-.014l-15.447-5.533h0l-.859-.307Zm1.906,4.673,14.64,5.245a4.741,4.741,0,0,0,.9.187l.065.008a4.745,4.745,0,0,0,.929.007l2.384,13.1-16.633-5.958Zm19.892,18.369-2.385-13.1a4.717,4.717,0,0,0,.842-.321c.04-.019.078-.04.117-.06a4.9,4.9,0,0,0,.741-.472l10.293-8.738,1.308-1.109.08-.068.183-.156,2.291,12.591Z" fill="#eceff0"/>
            </g>
        </g>
        <g id="bg">
            <path id="Path_2810" data-name="Path 2810" d="M1583.644-2402.858v3.454a7.6,7.6,0,0,0-1.742,1.167c-2.7,2.369-6.986,9.137-7.167,9.424l-.575.911,1.067-.149c.116-.016,2.785-.413,8.417-2.917v2.726h1v-3.181c2.623-1.217,4.169-2.614,4.585-4.153a3.507,3.507,0,0,0-.618-2.994,3.329,3.329,0,0,0-2.348-1.329,4.5,4.5,0,0,0-1.619.127v-2.087h70.22v52.895a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-53.895Zm2.5,3.953a2.325,2.325,0,0,1,1.649.911,2.512,2.512,0,0,1,.471,2.159c-.336,1.239-1.764,2.467-4.13,3.551a50.309,50.309,0,0,1-7.891,2.984c1.322-2.013,4.335-6.444,6.318-8.184a5.08,5.08,0,0,1,3.226-1.441A2.87,2.87,0,0,1,1586.144-2398.905Z" fill="#dae5ea"/>
            <g id="Capa_9" data-name="Capa 9">
            <g id="Group_6715" data-name="Group 6715">
                <line id="Line_244" data-name="Line 244" x1="850" transform="translate(1001.435 -2348.967)" fill="none" stroke="#a5b2b7" stroke-linecap="round" stroke-width="1"/>
                <g id="Group_6714" data-name="Group 6714">
                <path id="Path_2708" data-name="Path 2708" d="M1543-2348.964" fill="#fff" stroke="#a5b2b7" stroke-linecap="round" stroke-width="1"/>
                </g>
            </g>
            <g id="Group_6717" data-name="Group 6717">
                <rect id="Rectangle_1567" data-name="Rectangle 1567" width="86.515" height="14.318" transform="translate(1576.501 -2416.675)" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_2709" data-name="Path 2709" d="M1620.284-2417.2s7.584-12.013,12.194-16.057,8.763-2.907,10.294-.729c1.962,2.79,2.165,7.078-6.653,11.125C1625.314-2417.909,1620.284-2417.2,1620.284-2417.2Z" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_2710" data-name="Path 2710" d="M1619.159-2417.2s-7.583-12.013-12.194-16.057-8.764-2.907-10.294-.729c-1.962,2.79-2.164,7.078,6.654,11.125C1614.127-2417.909,1619.159-2417.2,1619.159-2417.2Z" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
                <g id="Group_6586" data-name="Group 6586">
                <g id="Group_6582" data-name="Group 6582">
                    <path id="Path_2647" data-name="Path 2647" d="M1633.463-2387.722a8.308,8.308,0,0,0-6.184-2.693,7.766,7.766,0,0,0-4.857,1.677,9.908,9.908,0,0,0-1.965,2.057,9.928,9.928,0,0,0-1.964-2.057,7.785,7.785,0,0,0-4.858-1.677,8.306,8.306,0,0,0-6.184,2.693,9.658,9.658,0,0,0-2.452,6.6,11.513,11.513,0,0,0,3.066,7.535,65.378,65.378,0,0,0,7.677,7.2c1.064.9,2.27,1.936,3.521,3.022a1.813,1.813,0,0,0,2.391,0c1.252-1.095,2.457-2.124,3.522-3.031a65.1,65.1,0,0,0,7.676-7.2,11.5,11.5,0,0,0,3.067-7.532A9.664,9.664,0,0,0,1633.463-2387.722Z" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
                </g>
                </g>
                <g id="Group_6716" data-name="Group 6716">
                <g id="Rectangle_1568" data-name="Rectangle 1568">
                    <path id="Path_2811" data-name="Path 2811" d="M1600.452-2379.436h-51.183v-9.306h51.183Zm-50.183-1h49.183v-7.306h-49.183Z" fill="#dae5ea"/>
                </g>
                <path id="Path_2713" data-name="Path 2713" d="M1574.513-2388.546s-4.4-6.967-7.073-9.314-5.084-1.685-5.971-.422c-1.138,1.619-1.256,4.11,3.86,6.452C1571.595-2388.953,1574.513-2388.546,1574.513-2388.546Z" fill="none" stroke="#dae5ea" stroke-linecap="round" stroke-width="1"/>
                <g id="Path_2714" data-name="Path 2714">
                    <path id="Path_2812" data-name="Path 2812" d="M1595.516-2348.464a.5.5,0,0,1-.5-.5v-30.471H1554.7v30.471a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-31.471h42.311v31.471A.5.5,0,0,1,1595.516-2348.464Z" fill="#dae5ea"/>
                </g>
                </g>
            </g>
            </g>
            <g id="Capa_10" data-name="Capa 10">
            <g id="Group_6720" data-name="Group 6720">
                <g id="Group_6719" data-name="Group 6719">
                <g id="Group_5935-2" data-name="Group 5935-2">
                    <path id="Path_2395-2" data-name="Path 2395-2" d="M1662.391-2349.352s-20.482-15.985-15.3-28.691,31.371,16.594,31.371,16.594-16.851-36.814,3.63-39.928,26.445,40.964,26.445,40.964,1.814-38.111,14.26-33.964,7.517,31.113,7.517,31.113,7.26-26.2,16.594-23.861,2.591,26.973,2.591,26.973,14.519-21.506,24.628-15.292,3.889,26.092,3.889,26.092Z" fill="#f0f3f7"/>
                    <g id="Group_5934-2" data-name="Group 5934-2">
                        <path id="Path_2396-2" data-name="Path 2396-2" d="M1800.744-2424.612c-4.687,8.119-14.3,11.345-21.461,7.216s-9.17-14.07-4.483-22.19,14.295-11.344,21.46-7.215S1805.433-2432.732,1800.744-2424.612Z" fill="#e9eef4"/>
                        <path id="Path_2397-2" data-name="Path 2397-2" d="M1803.227-2377.064c-6.3,4.848-14.872,4.272-19.15-1.285s-2.638-13.992,3.66-18.843,14.873-4.272,19.151,1.287S1809.527-2381.913,1803.227-2377.064Z" fill="#f0f3f7"/>
                        <ellipse id="Ellipse_387-2" data-name="Ellipse 387-2" cx="13.921" cy="14.979" rx="13.921" ry="14.979" transform="translate(1738.395 -2419.935) rotate(-26.104)" fill="#e9eef4"/>
                        <path id="Path_2398-2" data-name="Path 2398-2" d="M1809.175-2390.238c-.042-.341-.091-.679-.162-1.018l-12.669,3.817,5.883-12.025q-.474-.2-.962-.359l-6.255,12.78-13.073,3.939h0l-16.878,5.087,16.107-38.51h0l3.171-7.579,17.828-3.551c.134-.368.242-.74.353-1.11l-17.7,3.53,9.409-22.5c-.323-.118-.643-.238-.969-.33L1784-2425.933l-6.472-17.273c-.262.275-.526.549-.775.843l6.675,17.8-.995,2.376h0l-12.48,29.833-4.393-9.616h0l-.227-.5,4.3-16.914q-.345-.636-.741-1.226l-4.239,16.658-10.584-23.173c-.339.066-.675.149-1.01.246l6.156,13.477c-3.746-.4-11.5-1.208-15.788-1.492-.032.334-.049.672-.058,1.01,3.892.275,11.22,1.011,16.337,1.563l5.674,12.421h0l4.033,8.82-17.285,41.323.943.4,11.478-27.44,17.64-5.312h0l4.935-1.488,14.457,7.632c.334-.194.662-.411.986-.635l-14.05-7.416Z" fill="#ccd7e2"/>
                    </g>
                </g>
                <g id="Group_6718" data-name="Group 6718">
                    <g id="Group_5940-2" data-name="Group 5940-2">
                    <g id="Group_5938-2" data-name="Group 5938-2">
                        <g id="Group_5937-2" data-name="Group 5937-2">
                        <g id="Group_5936-2" data-name="Group 5936-2">
                            <path id="Path_2399-2" data-name="Path 2399-2" d="M1125.7-2350.564c-5.9,1.547-32.6,5.978-32.172-15.29.218-10.86-2.949-10.472-9.314-14.724s-6.214-6.981-5.652-13.058-5.5-3.135-9.537-11.125c-4.829-9.562.985-16.7,10.367-18.412s9.109,8.4,12.837,11.417,8.884-3.471,16.049,5.324c4.677,5.741-2.586,18.843,4.294,17.619,11.1-1.968,18.362,6.572,20.476,13.265C1135.727-2367.064,1131.281-2352.018,1125.7-2350.564Z" fill="#f0f3f7"/>
                        </g>
                        </g>
                    </g>
                    <g id="Group_5939-2" data-name="Group 5939-2">
                        <path id="Path_2400-2" data-name="Path 2400-2" d="M1125.7-2350.556c-.3-.41-15.341-23.954-41.835-54.375" fill="none" stroke="#fff" stroke-width="1"/>
                        <path id="Path_2401-2" data-name="Path 2401-2" d="M1098.638-2406.064l-1.164,17.327-13.931,1.154" fill="none" stroke="#fff" stroke-width="0.8"/>
                        <path id="Path_2465-4" data-name="Path 2465-4" d="M1105.8-2396.82l-1.163,17.325-13.932,1.155" fill="none" stroke="#fff" stroke-width="0.8"/>
                    </g>
                    <path id="Path_2465-5" data-name="Path 2465-5" d="M1112.009-2389.081l-1.163,17.327-13.932,1.154" fill="none" stroke="#fff" stroke-width="0.8"/>
                    <path id="Path_2465-6" data-name="Path 2465-6" d="M1118.495-2379.841l-1.168,17.326-13.926,1.151" fill="none" stroke="#fff" stroke-width="0.8"/>
                    </g>
                    <g id="Group_5945-2" data-name="Group 5945-2">
                    <g id="Group_5943-2" data-name="Group 5943-2">
                        <g id="Group_5942-2" data-name="Group 5942-2">
                        <g id="Group_5941-2" data-name="Group 5941-2">
                            <path id="Path_2402-2" data-name="Path 2402-2" d="M1075.661-2484.029c-12.385-10.083-15.345-41.251,2.3-50.708,21.817-11.7,44.657,6.406,47.531,25.026,2.217,14.362-7.828,24.39,18.312,34.919s22.107,23.6,16.733,48.611,12.294,15.834,26.024,36.939c10.88,16.725-4.41,33.785-11.225,40.146l-53.469-.26a39.456,39.456,0,0,1-4.952-3.436c-17.269-14.114-10.408-36.415-8.619-53.782,1.939-18.786-25.07-10.39-35.826-34.5S1088.044-2473.943,1075.661-2484.029Z" fill="#dfe6ed"/>
                        </g>
                        </g>
                    </g>
                    <path id="Path_2403-2" data-name="Path 2403-2" d="M1165.208-2349.464s-63.61-140.749-79.56-169.255" fill="none" stroke="#f5f5f5" stroke-width="1"/>
                    <g id="Group_5944-2" data-name="Group 5944-2">
                        <line id="Line_208-2" data-name="Line 208-2" x1="35.474" y1="1.881" transform="translate(1080.868 -2457.317)" fill="none" stroke="#f5f5f5" stroke-width="0.8"/>
                        <line id="Line_209-2" data-name="Line 209-2" x1="39.893" y1="4.302" transform="translate(1112.267 -2382.279)" fill="none" stroke="#f5f5f5" stroke-width="0.8"/>
                    </g>
                    <line id="Line_210-2" data-name="Line 210-2" x1="20.843" y2="44.854" transform="translate(1133.987 -2462.668)" fill="none" stroke="#fafbfb" stroke-width="0.8"/>
                    <path id="Path_2404-2" data-name="Path 2404-2" d="M1104.3-2513.831c1.734,2.443,4.75,3.371,6.745,2.073s2.2-4.333.464-6.777-4.751-3.37-6.746-2.071S1102.566-2516.274,1104.3-2513.831Z" fill="none" stroke="#fff" stroke-width="0.8"/>
                    <path id="Path_2405-2" data-name="Path 2405-2" d="M1103.027-2500.62a2.442,2.442,0,0,0,2.336,2.542h0a2.441,2.441,0,0,0,2.542-2.336,2.441,2.441,0,0,0-2.335-2.542h-.1a2.389,2.389,0,0,0-2.443,2.334Z" fill="none" stroke="#fff" stroke-width="0.8"/>
                    <path id="Path_2406-2" data-name="Path 2406-2" d="M1085.344-2440.315a4.744,4.744,0,0,0,4.593,4.89,4.744,4.744,0,0,0,4.886-4.49h0a4.743,4.743,0,0,0-4.538-4.939h-.2A4.643,4.643,0,0,0,1085.344-2440.315Z" fill="none" stroke="#fff" stroke-width="0.8"/>
                    </g>
                </g>
                </g>
            </g>
            </g>
            <g>
                <ellipse id="Ellipse_388-2" class="ellipse-388" data-name="Ellipse 388-2" cx="122.197" cy="11.038" rx="122.197" ry="11.038" transform="translate(1304.01 -2311.576)" fill="#f0f4f9"/>
            </g>
        </g>
        <g id="Group_7030" class="group-7030" data-name="Group 7030">
            <g id="Group_7029" data-name="Group 7029">
            <g id="Group_6799" data-name="Group 6799">
                <g id="Group_6798" data-name="Group 6798">
                <g id="Group_6797" data-name="Group 6797">
                    <path id="Path_2744" data-name="Path 2744" d="M1502.7-2570.764h-.19a33.216,33.216,0,0,1-33.22-33.212v-.008a30.23,30.23,0,0,1,.16-3.31,1.531,1.531,0,0,0-1.368-1.679h-.012a.783.783,0,0,0-.16-.01h-94.5a7.148,7.148,0,0,0-7.15,7.148v217.9a3.064,3.064,0,0,0,3.058,3.07h.012a3.138,3.138,0,0,0,1.7-.51l63.27-42.18a1.7,1.7,0,0,1,1.91,0l63.27,42.18a3.062,3.062,0,0,0,4.247-.846l0,0v-.01a3.023,3.023,0,0,0,.52-1.7v-185.284A1.55,1.55,0,0,0,1502.7-2570.764Zm-105.39,93.2a.937.937,0,0,1-.93.94h-7.61a.939.939,0,0,1-.94-.938v-7.6a.938.938,0,0,1,.938-.94h7.612a.937.937,0,0,1,.93.94Zm0-33.84v7.6a.937.937,0,0,1-.93.94h-7.61a.939.939,0,0,1-.94-.938v-7.6a.938.938,0,0,1,.938-.94h7.612a.935.935,0,0,1,.93.93Zm0-18.64a.937.937,0,0,1-.93.94h-7.61a.937.937,0,0,1-.94-.93v-7.61a.938.938,0,0,1,.938-.94h7.612a.935.935,0,0,1,.93.93Zm0-26.24a.937.937,0,0,1-.93.94h-7.61a.939.939,0,0,1-.94-.938v-7.6a.938.938,0,0,1,.938-.94h7.612a.937.937,0,0,1,.93.94Zm85.37,78.73a.937.937,0,0,1-.94.93h-77.13a.938.938,0,0,1-.94-.93v-7.61a.938.938,0,0,1,.938-.94h77.132a.939.939,0,0,1,.94.938v7.612Zm0-26.24a.937.937,0,0,1-.94.93h-77.13a.938.938,0,0,1-.94-.93v-7.61a.938.938,0,0,1,.938-.94h77.132a.939.939,0,0,1,.94.938v7.612Zm0-26.24a.937.937,0,0,1-.94.93h-77.13a.93.93,0,0,1-.94-.92v-7.62a.938.938,0,0,1,.938-.94h77.132a.939.939,0,0,1,.94.938v7.612Zm0-26.24a.937.937,0,0,1-.94.93h-77.13a.938.938,0,0,1-.94-.93v-7.61a.938.938,0,0,1,.938-.94h77.132a.939.939,0,0,1,.94.938v7.612Z" fill="#27333f"/>
                </g>
                </g>
            </g>
                <path id="Path_2746" data-name="Path 2746" d="M1502.511-2630.818a26.834,26.834,0,0,0-26.832,26.834h0a26.832,26.832,0,0,0,26.833,26.831h0a26.83,26.83,0,0,0,26.856-26.8,26.829,26.829,0,0,0-26.8-26.856h-.053Zm10.616,30.279c-3.407,3.333-7.064,6.4-10.718,9.682-3.694-3.4-7.354-6.59-10.806-9.992a10.482,10.482,0,0,1-2.416-11.815,7,7,0,0,1,6.006-4.383,6.9,6.9,0,0,1,6.793,3.7c.122.2.255.4.461.727a7.993,7.993,0,0,1,4.431-4.083c4.266-1.5,8.728,1.294,9.647,6.057a10.751,10.751,0,0,1-3.4,10.107Z" fill="#bc2c35" fill-rule="evenodd"/>
            </g>
        </g>
        </g>

        <g class="object varita" data-value="1">
            <g id="Varita">
                <path id="Star-2" :class="[init ? 'init-star-2' : '']" d="M1354.3-2421.634a6.632,6.632,0,0,1,6.632-6.632h0a6.632,6.632,0,0,1-6.632-6.63h0a6.632,6.632,0,0,1-6.63,6.632h0a6.63,6.63,0,0,1,6.631,6.631Z" fill="#c6d4db"/>
                <path id="Star-3" :class="[init ? 'init-star-3' : '']" d="M1398.376-2418.174a6.726,6.726,0,0,0-6.72,6.71,6.71,6.71,0,0,0-6.71-6.71,6.709,6.709,0,0,0,6.71-6.708v0A6.727,6.727,0,0,0,1398.376-2418.174Z" fill="#fff"/>
                <path id="star-4" :class="[init ? 'init-star-4' : '']" d="M1383.524-2453.594a6.453,6.453,0,0,0-6.45,6.45,6.451,6.451,0,0,0-6.45-6.45,6.453,6.453,0,0,0,6.45-6.45A6.454,6.454,0,0,0,1383.524-2453.594Z" fill="#fff"/>
                <path id="Path_2855" data-name="Path 2855" d="M1390.99-2437.808l-11.94,12.32a1.419,1.419,0,0,1-2,.04l-.01-.01-4.69-4.54a1.432,1.432,0,0,1-.03-2.01l11.95-12.31a1.421,1.421,0,0,1,2.01-.03l4.68,4.54A1.419,1.419,0,0,1,1390.99-2437.808Z" fill="#fff"/>
                <g class="rectangle-1584">
                    <rect id="Rectangle_1584" data-name="Rectangle 1584" width="67.735" height="9.368" rx="1.5" transform="matrix(0.696, -0.718, 0.718, 0.696, 1322.025, -2380.176)" fill="#bc2c35"/>
                </g>
            </g>
        </g>
    </g>
</icon-base>
</template>

<script>
export default {
name: 'iconEmptyWishList',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    init:{
        type: Boolean
    }
}
}
</script>

<style scoped>
    svg:hover > #Wishlist > #Wishlist-2 > #Group_7030 > #Group_7029 > #Path_2746, 
    svg:hover > #Wishlist > .object.varita > g > .rectangle-1584 > #Rectangle_1584 {
        fill: var(--light-main-red);
    }
    svg:hover > #Wishlist > .object.varita > g > #Star-2, svg:hover > #Wishlist > .object.varita > g > #Star-3, svg:hover > #Wishlist > .object.varita > g > #star-4 {
        fill: var(--yellow-600);
    }
    .object > g {
        animation: up-down 2s infinite alternate;
        transition: var(--transition-1);
    }
    .container-object {
        animation: up-down 2s infinite alternate;
        transition: var(--transition-1);
        animation-delay: 0.5s
    }
    .object > path {
        animation: up-down 2s infinite alternate;
        transition: var(--transition-1);
        animation-delay: 1s
    }
    @keyframes  up-down {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }
    .init.group-6743{
        animation: show-group-6743 1s
    }
    .init.group-6756{
        animation: show-group-6756 1s
    }
    .init.group-6762{
        animation: show-group-6762 1s
    }
    .init.ellipse-418{
        animation: show-group-6743 1s
    }
    .init.group-6734{
        animation: show-group-6734 1s
    }
    .init.group-6733{
        animation: show-group-6733 1s
    }
    .init.group-6745{
        animation: show-group-6745 1s
    }
    .init.ellipse-419{
        animation: show-group-6745 1s
    }
    .init.path-2729{
        animation: show-group-2729 1s
    }
    .group-7030, .object > .rectangle-1584, .object.varita > g{
        animation: animate-icon-wish-list 1.5s infinite alternate-reverse;
    }
    .ellipse-388{
        animation: ellipse-388 1.5s infinite alternate-reverse;
    }
    .init-star-4{
        animation: star-4 1s !important;
    }
    .init-star-3{
        animation: star-3 1s !important;
    }
    .init-star-2{
        animation: star-2 1s !important;
    }
    @keyframes animate-icon-wish-list {
        from{
            transform: translateY(10px);
        }
        to{
            transform: translateY(0px);
        }
    }
    @keyframes ellipse-388 {
        from{
            rx: 122;
        }
        to{
            rx: 100;
        }
    }
    @keyframes show-group-6743 {
        from{
            opacity:0;
            transform: translate(200px, 150px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes show-group-6756 {
        from{
            opacity:0;
            transform: translate(-200px, 150px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes show-group-6762 {
        from{
            opacity:0;
            transform: translate(-200px, 50px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes show-group-6734 {
        from{
            opacity:0;
            transform: translate(200px, 0px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes show-group-6733 {
        from{
            opacity:0;
            transform: translate(200px, -30px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes show-group-6745 {
        from{
            opacity:0;
            transform: translate(-200px, 0px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes show-group-2729 {
        from{
            opacity:0;
            transform: translate(-200px, 200px);
        }to{
            opacity:1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes star-4 {
        from{
            opacity: 0;
            transform: translate(10px, 20px);
        }
        to{
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes star-3 {
        from{
            opacity: 0;
            transform: translate(-5px, -20px);
        }
        to{
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
    @keyframes star-2 {
        from{
            opacity: 0;
            transform: translate(40px, -20px);
        }
        to{
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
</style>

        <!-- animation: up-down 1s infinite alternate; -->
