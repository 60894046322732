<template>
<div class="wishlist-table col-md-12 animation-slide-down" id="wishlist-table" :style="[{'padding-bottom': paddingBottom}]">
    <table class="table table-hover color-dark-main-navy size-15 weight-400 mx-auto pe-0">
        <thead>
            <tr>
                <th class="weight-600 d-flex align-items-center pb-3"> 
                    <div class="d-flex" id="wishlist-table-exception">
                        <main-checkbox v-if="selectListView.show || desktopViewPort" id="select-all" :value="selectedAll" @change="selectAll" class="ms-0 ms-md-2 me-3" />
                        <span id="checkbox-label">
                            {{$t("wishlist.list_name")}}
                        </span>
                    </div>
                </th>
                <template v-if="desktopViewPort">
                    <th v-for="label in ['public','subtotal', 'action']" :key="label" class="text-center weight-600 pb-3">{{$t(`wishlist.${label}`)}}</th>
                </template>
            </tr>
        </thead>
        <tbody id="wishlist-table-body"> <!-- Quitar el border-color del último tab y añadirle justo debajo algun div que cuente como una línea, la cual SI tendrá un margen que nos dará el suficiente espacio para que quepa el modalsito -->
            <tr v-for="list in lists" v-long-press="800" @long-press-start="enableSelectView(list)" :key="list._id" id="select-list" @click="select($event, list)" class="cursor-pointer">
                <template v-if="desktopViewPort">
                    <td class="wishlist-info align-middle" id="open">
                        <div class="d-flex" id="open">
                            <div id="open" class="flex-grow-0"> 
                                <main-checkbox :values="selectedListIds" :value="list._id" @change="selectList(list)" isList :id="'select-wishlist-'+list._id" class="ms-2 me-3" />
                            </div>
                            <div class="flex-grow-0" @click="goDetail(list._id)">
                                <wrapper-image :images="list.products" :wrapperHeight="100" :wrapperWidth="100" :imgHeight="48"/>
                            </div>
                            <!-- delete all lists needs to check the first check-box -->
                            <div class="d-flex flex-column justify-content-center flex-grow-3 ms-4" id="open" style="min-width: 175px;">
                                <span class="d-flex" id="open">
                                    <transition name="name-change" mode="out-in" appear>
                                        <h6 class="title-15 mb-1 text-limited hover-blue-600" id="open" :title="list.name" :key="list.name" data-toggle="tooltip" data-placement="top">
                                            {{list.name}}
                                        </h6>
                                    </transition>
                                    <div class="edit-name-area d-flex" :class="{'position-relative': change.overflow}" :id="`disable-route-${list._id}`">
                                        <icon-edit :size="15" class="ms-2 pb-2" id="btn-edit" @click.native="assign(list)" :color="change.value && change.id === list._id ? 'var(--blue-600)':'var(--gray-1001)'" />
                                        <half-circle-spinner v-if="editingList.value && editingList.id === list._id" :animation-duration="1000" :size="20" color="var(--main-red)" class="ms-2" />
                                        <edit-wish-list-pop-up v-if="change.value && change.id === list._id" @changing="handleEdit"
                                        :listSelected="list" @hideEdit="change.value = false" :style="[!change.overflow ? change.absolute_position : { top: '30px', left: '-28px'}]" />
                                    </div>
                                </span>
                                <h6 class="subtitle-13 mb-1 color-dark-gray-200 weight-500" id="open">{{list.products.length}} {{$t("wishlist.saved_products")}}</h6>
                                <h6 class="subtitle-13 mb-1 color-dark-gray-200 weight-500" id="open" v-if="days(list.updatedAt) > 0">{{$t("wishlist.updated_ago")}} {{days(list.updatedAt)}} {{$t("wishlist.days")}}</h6>
                                <h6 class="subtitle-13 mb-1 color-dark-gray-200 weight-500" id="open" v-else>{{$t("wishlist.updated_less_than_a_day_ago")}}</h6>
                            </div>
                        </div>
                    </td>
                    <td class="align-middle title-14 weight-700 text-center responsive-column" id="open">
                        <main-switch class="justify-content-center" id="select-public" />
                    </td>
                    <td class="align-middle title-14 weight-700 text-center responsive-column" id="open">{{showValueMoney()}}$ {{parseFloat(amount(list)).toFixed(2)}}<b class="color-light-main-red weight-700"></b> </td>
                    <td class="align-middle text-center responsive-column" id="open">
                        <div class="d-flex flex-column-reverse flex-lg-row ms-2 justify-content-center">
                            <div class="d-flex justify-content-center">
                                <a href="#" class="btn-download" @click.prevent="">
                                    <icon-download :size="24" color="var(--gray-1001)" />
                                </a>
                                <a href="#" class="btn-trash ms-3 mx-lg-3" @click.prevent="removeList(list)">
                                    <icon-trash :size="26" color="var(--gray-1001)" />
                                </a>
                            </div>
                            <div class="d-flex justify-content-center mb-3 mb-lg-0">
                                <a href="#" class="btn-cart" @click.prevent="">
                                    <icon-cart :size="26" color="var(--gray-1001)" />
                                </a>
                                <a href="#" class="btn-cart ms-3" @click.prevent="share = { value: true, product: { _id: list._id } }">
                                    <icon-share :size="23" color="var(--gray-1001)" />
                                </a>
                            </div>
                        </div>
                    </td>
                </template>
                <template v-else> <!-- mobile-version -->
                    <td class="d-flex py-4 mobile" id="open" :class="{'pointer-events-none': selectListView.show}">
                        <div v-if="selectListView.show" id="open" class="flex-grow-0">
                            <main-checkbox :values="selectedListIds" :value="list._id" @change="selectList(list)" isList :id="'select-wishlist-'+list._id" class="me-3" />
                        </div>
                        <div id="open" class="flex-grow-0" @click="goDetail(list._id)">
                            <wrapper-image :images="list.products" :wrapperHeight="100" :wrapperWidth="100" :imgHeight="48"/>
                        </div> <!-- delete all lists needs to check the first check-box -->
                        <div class="d-flex flex-grow-1 flex-column justify-content-start ms-3" :class="{'justify-content-center': selectListView.show}" id="open" style="min-width: 175px;">
                            <div class="d-flex align-items-center">
                                <h6 class="d-block title-15 mb-0 me-auto text-limited hover-blue-600" style="width: 145px;" id="open" data-toggle="tooltip" data-placement="top" :title="list.name" :key="list.name">
                                    {{list.name}}
                                </h6>
                                <div class="dots-container position-relative" :class="{'d-none': selectListView.show}" @click="mobileOptions = list._id">
                                    <icon-dot-menu class="cursor-pointer align-content-center" color="var(--gray-1001)" :size="25" style="width: auto;" />
                                    <mobile-options-menu v-if="mobileOptions === list._id" style="top: -22px; left: -138px;" @edit_wishlist="editWishList = list._id"
                                    :options="options" @hide="mobileOptions = ''" @delete="removeList(list)" @share="share = { value: true, product: { _id: list._id } }" />
                                </div>
                                <edit-wishlist v-if="editWishList === list._id" @closeModal="() => {editWishList = '', mobileOptions = ''}" :listSelected="list" />
                            </div>
                            <h6 class="subtitle-13 color-dark-gray-200 weight-400" id="open">{{list.products.length}} {{$t("wishlist.saved_products")}}</h6>
                            <div class="d-flex" :id="`disable-route-${list._id}`" :class="{'d-none': selectListView.show}">
                                <main-switch id="select-public" class="align-items-center" :description="$t('wishlist.public')"/>
                            </div>
                            <h5 id="open" class="mb-0" :class="{'mt-auto': !selectListView.show}">
                                <span id="open" class="weight-600 size-16 color-main-red">{{showValueMoney()}}$</span>
                                <span id="open" class="size-16 ms-2 color-dark-main-navy">{{parseFloat(amount(list)).toFixed(2)}}</span>
                            </h5>
                        </div>
                    </td>
                </template>
            </tr>
        </tbody>
    </table>
    <share v-if="share.value" @closeModal="share.value = false" :product="share.product" />
    <delete-wish-list v-if="deleteWishList" @closeModal="cancel" @confirmDelete="deleteSelection" :deleteLabels="deleteLabels" :listNames="selectedListNames" />
</div>
</template>
<script>
import { productMixin } from "./../../products/helpers/mixin/product-mixin";
import WrapperImage from '../../../common/components/images/WrapperImage.vue'
import DeleteWishList from './modals/DeleteWishList.vue'
import Share from '../../products/components/modals/Share.vue'
import EditWishListPopUp from './EditWishListPopUp';


import MainSwitch from '../../../common/components/switch/MainSwitch.vue'
import MainCheckbox from '../../../common/components/checkbox/MainCheckbox.vue'
import IconDownload from '../../../common/svg/iconDownload.vue'
import IconCart from '../../../common/svg/iconCart.vue'
import IconShare from '../../../common/svg/iconShare.vue'
import IconTrash from '../../../common/svg/iconTrash.vue'
import IconEdit from '../../checkout/assets/svg/iconEdit.vue'
import IconDotMenu from '../../../common/svg/iconDotMenu.vue'

import MobileOptionsMenu from '@/modules/products/components/menu/MobileOptionsMenu.vue';
import EditWishlist from '../components/modals/EditWishlist.vue'
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    components: {
        DeleteWishList, 
        EditWishlist,
        MainSwitch,
        MainCheckbox,
        WrapperImage,
        Share,
        EditWishListPopUp,
        HalfCircleSpinner,
        IconDotMenu,
        IconCart,
        IconTrash,
        IconEdit,
        IconDownload,
        IconShare,
        MobileOptionsMenu
    },
    name: "WishListTable",
    mixins: [productMixin],
    data() {
        return {
            options: [
                { size: 22, icon: IconTrash, label: 'product.delete' },
                { size: 18, icon: IconCart, label: 'cart.buy' },
                { size: 16, icon: IconShare, label: 'product.share', },
                { size: 16, icon: IconDownload, label: 'product.download' },
                { size: 16, icon: IconEdit, label: 'product.edit_wishlist' }
            ],
            deleteWishList: false,
            selectedListIds: [],
            selectedListNames: [],
            selectedList: {},
            selectedAll: false,
            desktopViewPort: false,
            deleteLabels: { delete_all: false, html: {}, title: 'wishlist.delete_list_modal.title_singular', description: 'wishlist.delete_list_modal.description_singular', single_quoted: true },
            share: { value: false, product: null },
            change: { value: false, id: '', old: '', name: '', overflow: false, top: '0px', left: '0px' },
            mobileOptions: '',
            editWishList: '',
            selectListView: {
                show: false,
                mouseDown: false,
            },
            count: {
                select: null,
                scroll: null
            }
        }
    },
    watch: {
        async 'change.value'(val) {
            if (val) {
                this.changeNameTimeout = setTimeout(() => {
                    document.body.addEventListener('click', this.handleOutsideNoteClick);
                }, 100);
            }else {
                this.updateName();
                document.body.removeEventListener('click', this.handleOutsideNoteClick);
            }
        },
        selectedListIds(val){
            this.selectedAll = val.length === this.lists.length;
            const mode = val.length > 1 ? 'plural':'singular';
            if (this.selectedAll) {
                this.deleteLabels.delete_all = true;
                this.deleteLabels.html.first = 'wishlist.delete_list_modal.title_all_1';
                this.deleteLabels.html.second = 'wishlist.delete_list_modal.title_all_2';
                this.deleteLabels.html.third = 'wishlist.delete_list_modal.title_all_3';
            } else {
                this.deleteLabels.delete_all = false;
                this.deleteLabels.html = {};
            }
            this.deleteLabels.title = 'wishlist.delete_list_modal.title_'+mode;
            this.deleteLabels.description = 'wishlist.delete_list_modal.description_'+mode;
            this.$emit("selectedLists", val);
        },
    },
    computed: {
        paddingBottom() {
            if (!this.change.value) return '0px';
            if (!this.change.overflow) return '0px';
            if (this.change.id === this.lists.at(-1)._id){ // evaluating if its the last or the second_last 
                return '185px';
            }else if (this.change.id === this.lists.at(-2)._id){
                return '54px';
            }else {
                return '0px';
            }
        }
    },
    methods: {
        async enableSelectView(list) {
            if (this.desktopViewPort) return;
            this.selectListView.show = !this.selectListView.show;

            await this.$nextTick(() => {
                document.querySelector(`#select-wishlist-${list._id}`).click();
                this.selectListView.mouseDown = true;
            });
        },
        handleEdit() {
            this.change.index = this.lists.findIndex((list)=> list._id === this.change.id);
        },
        selectAll() {
            this.selectedAll = !this.selectedAll;
            if (this.selectedAll) {
                this.lists.map((list) => {
                    if (this.selectedListIds.findIndex((_list) => _list === list._id) === -1 ) { //only add it they're not already there
                        this.selectedListIds.push(list._id);
                        this.selectedListNames.push(list.name);
                    }
                });
            }else {
                this.cleanSelected();
                this.selectListView.show = false
            }
        },
        handleOutsideNoteClick(evt) {
            const el = document.getElementById('edit-wishlist');
            if (!el || !(el == evt.target || el.contains(evt.target)) && evt.target.id !== 'btn-edit') this.change.value = false;
        },
        days(data){
            const date1 = this.moment().format('MM-DD-YYYY');
            const date2 = this.moment(data.slice(0,10));
            const time = date2.diff(date1, 'days');
            return time;
        },
        async deleteList() {
            try {
                await this.$store.dispatch('wishlist/destroyList', this.selectedList._id);
                this.deleteWishList = false;
            }catch (e) {
                console.log(e);
            }
        },
        amount(list) { //get the total amount from a product
            let total = 0;
            list.products.map((product) => { total += product.product.price });
            return total;
        },
        selectList(list) {
            const index = this.selectedListIds.findIndex(product => product === list._id);
            if(index >= 0) {
                this.selectedListIds.splice(index, 1);
                this.selectedListNames.splice(index, 1);
            } else {
                this.selectedListNames.push(list.name);
                this.selectedListIds.push(list._id);
            }
        },
        checked(list) { return list._id === this.selectedList._id; },
        async updateName() {
            const name = this.change.name.replace(/ +(?= )/g,''); //removing multiple spaces
            const valid = this.change.old !== this.change.name && name && name !== ' ' && !name.match(/\s{2,}/g);
            if (valid) await this.$store.dispatch("wishlist/addProductToList", {list_id: this.change.id, name, action: "change-name"});
        },
        async assign({_id, name}) {
            const btn_edit = document.querySelector(`#disable-route-${_id}`).querySelector('#btn-edit');
            const getPosition = (el, position) => el.getBoundingClientRect()[position]
            const top = getPosition(btn_edit, 'top') + 30 + window.scrollY+ 'px';
            const left = getPosition(btn_edit, 'left') - 35 + 'px';
            
            if (this.change.value) this.updateName();
            this.change = {
                id: _id,
                value: true,
                name,
                old: name,
                overflow: this.desktopViewPort && this.lists.length >= (this.viewPort('lg') ? 5 : 4),
                absolute_position: {
                    top,
                    left,
                }
            }
        },
        goDetail(list_id){
            this.$router.push({ name: 'wishlist-detail', params: { wishlist_id: list_id }})
        },
        select(evt, list) {
            if (this.selectListView.show){  //click only if it is not the first one
                if (!this.selectListView.mouseDown) {
                    document.querySelector(`#select-wishlist-${list._id}`).click();
                    if (this.selectedListIds.length == 0) { // if there's nothing selected close it
                        this.cleanSelected();
                        this.selectListView.show = false;
                    }
                }
                this.selectListView.mouseDown = false;
            }else {
                const el = document.getElementById('disable-route-'+list._id);
                if (evt.target.id === "open" && !(el == evt.target || el?.contains(evt.target))) this.goDetail(list._id);
            }
        },
        deleteSelection() {
            this.selectedListIds.map(async (list) => {
                await this.$store.dispatch('wishlist/destroyList', list)
            });
            this.deleteWishList = false;
            this.selectedListIds = [];
        },
        removeList(list) {
            this.cleanSelected();
            this.selectedListIds.push(list._id);
            this.selectedListNames.push(list.name);
            this.deleteWishList = true;
        },
        cancel(){
            this.cleanSelected();
            this.deleteWishList = false;
        },
        cleanSelected() {
            this.selectedListIds = [];
            this.selectedListNames = [];
        },
        showDelete() {
            if (this.selectedListIds.length === 0) {
                this.lists.map((list) => {
                    if (this.selectedListIds.findIndex((_list) => _list === list._id) === -1 ) { //only add it they're not already there
                        this.selectedListIds.push(list._id);
                        this.selectedListNames.push(list.name)
                    }
                });
            }
            this.deleteWishList = true;
        },
        clickOutsideListView(evt) {
            if (!this.selectListView.show) return;
            const table = document.querySelector('#wishlist-table-body');
            const exception = document.querySelector('#wishlist-table-exception');
            if ( !(table == evt.target || table?.contains(evt.target) || exception == evt.target || exception?.contains(evt.target)) ) {
                this.cleanSelected();
                this.selectListView.show = false;
            }
        }
    },
    created(){
        document.addEventListener('click', this.clickOutsideListView);
        this.desktopViewPort = this.viewPort('md');
    },
    destroyed(){
        document.removeEventListener('click', this.clickOutsideListView)
        clearTimeout(this.changeNameTimeout);
        clearTimeout(this.count.scroll);
    }
}
</script>
<style scoped>
/*  the total-height is the height of every wishlist multiplied by its number.  */
    thead { border-bottom: 2px solid #a5b2b717 !important; }
    .modal-main-container.delete-wishlist::v-deep .modal-main {
        max-width: 278px;
    }
    td { padding-block: 1rem; }
    .table {
        --bs-table-hover-bg: #f4f7f93d;
    }
    a:hover svg.icon-edit {
        fill: var(--main-red) !important;
    }
    .btn-download:hover svg::v-deep path {
        fill: var(--blue-600) !important;
        stroke: var(--blue-600) !important;
    }
    .edit-name-area svg { transition: var(--transition-1) }
    .edit-name-area:hover svg { fill: var(--blue-600) }
    .responsive-column {
        padding: 50px;
    }
    .wishlist-table {
        min-height: 380px;
        max-height: 682px;
        overflow: auto;
    }
    .popup::v-deep::before { /* aligning our pointy thingy */
        top: 0;
        left: 9%;
        transform: translate(50%, -50%) rotate(314deg);
    }
    .main-input::v-deep span.input-group-text {
        display: none;
    }
    .main-input::v-deep .form-control {
        border-radius: 7px !important;
        border: 1px solid var(--gray-100) !important;
        padding-left: .75rem;
    }
    .name-change-enter-active, .name-change-leave-active {
        transition: opacity .25s ease-in-out;
    }
    .name-change-enter-from, .name-change-leave-to {
        opacity: 0;
    }
    .dots-container {
        border-radius: 3.123rem;
        height: 30px;
        width: 30px;
    }
    .dots-container:hover {
        background-color: var(--light-gray-100);
    }

    @media (max-width: 767px) {
        .wishlist-table {
            padding-inline: 10px;
            padding-bottom: 10px;
        }
        .mobile-options-padding.wishlist-table {
            padding-bottom: 50px;
        }
        .main-switch::v-deep label {
            top: 1px;
            height: 12px !important;
            width: 28px;
        }
        .main-switch::v-deep label svg {
            width: 12px;
            height: 12px;
            margin-top: 0;
            transform: translateX(0.4em);
        }
        .main-switch::v-deep label.checked svg { transform: translateX(-0.7em) !important; }
        .icon-close { transition: var(--transition-1); }
        .icon-close:hover, .icon-close:active { fill: var(--main-red); }
        .icon-share, .icon-download, .icon-cart {
            width: 22px;
            transition: var(--transition-1);
        }
        .icon-share:active, .icon-cart:active { fill: var(--main-red); }
        .icon-download::v-deep:active g path {
            fill: var(--blue-600) !important;
            stroke: var(--blue-600) !important;
        }
        .icon-cart { margin-inline: 6px !important; }
    }

    @media (max-width: 1200px) {
        .responsive-column {
            padding: 50px 20px;
        }
        .modal-main-container.delete-wishlist::v-deep .close-container {
            padding-top: 0px;
        }
    }

    @media (max-width: 992px) {
        .responsive-column {
            padding: 50px 10px;
        }
    }

    @media (min-width: 768px) {
        .wishlist-info {
            max-width: 290px;
        }
    }
    @media (min-width: 992px) {
        .wishlist-info {
            width: 45%;
        }
    }
</style>
